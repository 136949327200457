export default [
  {
    Charging_Station: 'Station_1',
    AGV_Name: 'AGV_1',
    SOC: 11,
    Voltage: 24.83,
    Current: 35.08,
    Charging_Time: 11,
    Temperature: 47
  },
  {
    Charging_Station: 'Station_2',
    AGV_Name: 'AGV_2',
    SOC: 67,
    Voltage: 24.56,
    Current: 31.58,
    Charging_Time: 46,
    Temperature: 43
  },
  {
    Charging_Station: 'Station_3',
    AGV_Name: 'AGV_3',
    SOC: 36,
    Voltage: 24.38,
    Current: 28.58,
    Charging_Time: 28,
    Temperature: 40
  },
  {
    Charging_Station: 'Station_4',
    AGV_Name: 'AGV_4',
    SOC: 78,
    Voltage: 24.48,
    Current: 31.93,
    Charging_Time: 16,
    Temperature: 33
  },
  {
    Charging_Station: 'Station_5',
    AGV_Name: 'AGV_5',
    SOC: 43,
    Voltage: 24.18,
    Current: 30.99,
    Charging_Time: 14,
    Temperature: 46
  },
  {
    Charging_Station: 'Station_6',
    AGV_Name: 'AGV_6',
    SOC: 94,
    Voltage: 24.58,
    Current: 14.82,
    Charging_Time: 38,
    Temperature: 32
  },
  {
    Charging_Station: 'Station_7',
    AGV_Name: 'AGV_7',
    SOC: 26,
    Voltage: 24.9,
    Current: 28.44,
    Charging_Time: 45,
    Temperature: 35
  },
  {
    Charging_Station: 'Station_8',
    AGV_Name: 'AGV_8',
    SOC: 8,
    Voltage: 24.14,
    Current: 39.54,
    Charging_Time: 23,
    Temperature: 40
  },
  {
    Charging_Station: 'Station_9',
    AGV_Name: 'AGV_9',
    SOC: 9,
    Voltage: 25.78,
    Current: 37.84,
    Charging_Time: 40,
    Temperature: 32
  }
]
