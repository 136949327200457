export default [
  {
    Charging_Station: 'Station_1',
    Transmitter_Information: {
      'Model Number': 'TX30BE-1A00',
      'Voltage Rating': 'Universal',
      'Serial Number': '04180001',
      'Manufactured Date': '15/05/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/05/2020'
    }
  }, {
    Charging_Station: 'Station_2',
    Transmitter_Information: {
      'Transmitter ID': '002',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  },
  {
    Charging_Station: 'Station_3',
    Transmitter_Information: {
      'Transmitter ID': '003',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_4',
    Transmitter_Information: {
      'Transmitter ID': '004',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_5',
    Transmitter_Information: {
      'Transmitter ID': '005',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_6',
    Transmitter_Information: {
      'Transmitter ID': '006',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_7',
    Transmitter_Information: {
      'Transmitter ID': '007',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_8',
    Transmitter_Information: {
      'Transmitter ID': '008',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_9',
    Transmitter_Information: {
      'Transmitter ID': '009',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_10',
    Transmitter_Information: {
      'Transmitter ID': '0010',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_11',
    Transmitter_Information: {
      'Transmitter ID': '0011',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }, {
    Charging_Station: 'Station_12',
    Transmitter_Information: {
      'Transmitter ID': '0012',
      'Voltage Rating': '12',
      'Manufactured Date': '15/07/2020',
      'Hardware Version': '1.4.5',
      'Software Version': '3.4.5',
      'Install Date': '31/08/2020',
      'Remove Date': '31/08/2022'
    }
  }]
