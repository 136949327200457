<template>
  <div class="chart-container">
    <scatter-chart :chart-data="dataCollection" :options="chartSettings"></scatter-chart>
  </div>
</template>

<script>
import ScatterChart from '@/ChartJavaScripts/ScatterChart'

export default {
  components: {
    ScatterChart
  },
  props: {
    agvData: Array
  },
  data () {
    return {
      filteredData: [{
        timestamp: 0,
        Start_Voltage: 0
      }],
      dataCollection: {},
      chartSettings: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time'
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Voltage (V)'
              },
              ticks: {
                min: 20,
                max: 30
              }
            }
          ]
        }
      }
    }
  },
  computed: {},
  methods: {
    fillData () {
      this.dataCollection = {
        datasets: [{
          label: 'Start Voltage of AGV',
          borderColor: '#f9917a',
          data: this.fillData2()
        }]
      }
    },
    fillData2 () {
      const z = []
      this.filteredData.forEach(element => {
        z.push({
          x: element.timestamp,
          y: element.Start_Voltage
        })
      })
      return z
    }
  },
  watch: {
    agvData: function () {
      this.filteredData = this.agvData
      this.fillData()
    },
    filteredData: function () {
      console.log('watched')
      this.fillData()
    }
  },
  created () {
    console.log('before')
    this.filteredData = this.agvData
    console.log('Mounted')
  }
}
</script>

<style>
.chart-container {
  //height: 40vh;
  width: 80vw;
  margin: auto
}
</style>
