<template>
  <div class="home">
    <home-overview-bar></home-overview-bar>
    <home-tx-view-box :chargingDataArray="chargerDetails"
                      v-bind:battery_voltage="battery_voltage"
                      v-bind:battery_current="battery_current"
                      v-bind:max_current="90"
                      v-bind:max_voltage="30"
                      v-bind:min_voltage="20"
                      v-bind:charger_status="charger_status"
                      v-bind:rx_error_code_hi="rx_error_code_hi"
                      ></home-tx-view-box>
  </div>
</template>

<script>
import HomeTxViewBox from '../components/HomeTxViewBox'
import HomeOverviewBar from '../components/HomeOverviewBar'

export default {
  components: {
    HomeOverviewBar,
    HomeTxViewBox
  },
  data () {
    return {
      battery_voltage: 0,
      battery_current: 0,
      charger_status: 0,
      rx_error_code_hi: 0
    }
  },
  computed: {
    chargerDetails: function () {
      return this.$store.state.dev.chargerDetails
    }
  },

  methods: {
    msg_parsing (d) {
      for (let i = 0; i < d.length; i++) {
        // console.debug(d[i].label, d[i].value, d[i].address)
        if (d[i].label === 'RX_OUTPUT_VOLTAGE') {
          this.battery_voltage = d[i].value
          console.info('RX_OUTPUT_VOLTAGE battery_voltage', this.battery_voltage)
        } else if (d[i].label === 'RX_OUTPUT_CURRENT') {
          this.battery_current = d[i].value
          console.info('RX_OUTPUT_CURRENT battery_current', this.battery_current)
        } else if (d[i].label === 'charger_status') {
          this.charger_status = d[i].value
          console.info('charger_status charger_status', this.charger_status)
        } else if (d[i].label === 'RX_error_code_hi') {
          this.rx_error_code_hi = d[i].value
          console.info('RX_error_code_hi rx_error_code_hi', this.rx_error_code_hi)
        }
      }
    },

    initWebSocket () {
      this.$socket.onopen = this.websocketonopen
      this.$socket.onerror = this.websocketonerror
      this.$socket.onmessage = this.websocketonmessage
      this.$socket.onclose = this.websocketclose
    },
    websocketonopen (e) {
      // 0 CONNECTING ,1 OPEN, 2 CLOSING, 3 CLOSED
      console.log('WebSocket连接成功', e)
    },

    websocketonerror (e) {
      console.log('WebSocket连接发生错误', e)
    },

    websocketonmessage (e) {
      const ws_msg = JSON.parse(e.data)

      if (ws_msg.type === 'data') {
        this.ws_data = ws_msg.data
        // console.debug(this.ws_data)
        this.msg_parsing(this.ws_data)
      }
    },

    websocketsend (e) {
      // this.$socket.send(JSON.stringify({test: 'www..com'}));
      // this.$socket.close(1000)
      console.log(this.text)
      console.log(this.$socket.readyState, e)
    },

    websocketclose (e) {
      const code = e.code //  状态码表 https://developer.mozilla.org/zh-CN/docs/Web/API/CloseEvent
      const reason = e.reason
      const wasClean = e.wasClean
      console.warn(code, reason, wasClean)
    }
  },

  created () {
    this.initWebSocket()
  }

}

</script>
