import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '../views/HomePage.vue'
import AgvReportPage from '../views/AgvReportPage.vue'
import ChargerReportPage from '../views/ChargerReportPage.vue'
import login from '../components/auth/Login.vue'
import store from '../store'
import Logout from '../components/auth/Logout'
import LandingPage from '../components/marketing/LandingPage'
import HistoricalPage from '../views/HistoricalPage.vue'

import BatteryCalculator from '../components/BatteryCalculator'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: LandingPage
    },
    {
      path: '/tx',
      name: 'tx',
      component: HomePage,
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/agv-report/:id',
      name: 'AgvReport',
      component: AgvReportPage,
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/charger-report/:id',
      name: 'ChargerReport',
      component: ChargerReportPage,
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/login',
      name: 'login',
      component: login,
      meta: {
        requiresVisitor: true
      }
    }, {
      path: '/logout',
      name: 'logout',
      component: Logout
    }, {
      path: '/history',
      name: 'history',
      component: HistoricalPage,
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/calculator',
      name: 'calculator',
      component: BatteryCalculator,
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/status',
      name: 'Status',
      component: () => import('../views/smart_module.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['web_auth/loggedIn']) {
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters['web_auth/loggedIn']) {
      next({
        name: 'tx'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
