<template>
  <b-container>
    <b-row>
      <b-col lg="6">
        <b-card bg-variant="light" text-variant="dark" title="Transmitter Operating Status">
          <b-row>
            <b-col md="6">
              <b-input-group prepend=" Standby" class="mt-3">
                <b-form-input v-model="tx_num_standby" readonly></b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group prepend="Charging" class="mt-3">
                <b-form-input v-model="tx_num_charging" readonly></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-input-group prepend="   Error" class="mt-3">
                <b-form-input v-model="tx_num_error" readonly></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="6">
              <b-input-group prepend=" Offline" class="mt-3">
                <b-form-input v-model="tx_num_offline" readonly></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card bg-variant="light" text-variant="dark" title="Factory: Xnergy">
          <b-row>
            <b-col md="6">
              <b-input-group prepend="Transmitter" class="mt-3">
                <b-form-input value="70" readonly></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="6">
              <b-input-group prepend="Transmitter in Op" class="mt-3">
                <b-form-input value="45" readonly></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-input-group prepend="Receiver" class="mt-3">
                <b-form-input value="120" readonly></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="6">
              <b-input-group prepend="Receiver in Op" class="mt-3">
                <b-form-input value="109" readonly></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-card v-show="tx_num_error" style="margin: 10px auto" border-variant="danger"
              header-border-variant="danger"
              header-text-variant="danger"
              header="Error"
      >
        <b-card-title>
          Transmitter: 04180001
        </b-card-title>
        <b-card-sub-title>
          <!--          <b-icon icon="clock" variant="danger" animation="spin"></b-icon>-->
          <!--          00:16:03-->
        </b-card-sub-title>
        <b-card-text>
          Please remove all the foreign object from the transmitter pad.
          Keep it clean all the time.
        </b-card-text>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'HomeOverviewBar',
  data () {
    return {}
  },
  computed: {
    tx_num_standby () {
      return this.$store.state.dev.tx.num_standby
    },
    tx_num_error () {
      return this.$store.state.dev.tx.num_error
    },
    tx_num_charging () {
      return this.$store.state.dev.tx.num_charging
    },
    tx_num_offline () {
      return this.$store.state.dev.tx.num_offline
    }

  }
}
</script>

<style scoped>

</style>
