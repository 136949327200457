import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import './styles/main.css'

// import App from './App'
import router from './router'
import store from './store'
import websocket from 'vue-native-websocket'
import Master from './components/layout/Master'

// Vue.use(websocket, 'wss://ims.xnergy.tech:8889', {
//   reconnection: true,
//   reconnectionAttempts: 5,
//   reconnectionDelay: 3000,
//   format: 'json'
// })

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(Master)
}).$mount('#app')
