<template>
  <div class="p-5">
    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter On"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            description="Leave all unchecked to filter on all data"
            class="mb-0">
            <b-form-checkbox-group v-model="filterOn" class="mt-1">
              <b-form-checkbox value="AGV_Name">AGV Name</b-form-checkbox>
              <b-form-checkbox value="Charging_Station">Charging Station</b-form-checkbox>
              <b-form-checkbox value="Date">Date</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <scatter-graph :agv-data="charging_history"></scatter-graph>

      <!-- Main table element -->
      <b-table
        striped
        show-empty
        small
        stacked="md"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filterIncludedFields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(AGV_Name)="data">
          <router-link :to="`/agv-report/${data.value}`">
            AGV   {{ data.value }}
          </router-link>
        </template>
        <template v-slot:cell(Charging_Station)="data">
          <router-link :to="`/charger-report/${data.value}`">
            {{ data.value }}
          </router-link>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import ScatterGraph from '@/components/ScatterGraph'

export default {
  components: { ScatterGraph },
  data () {
    return {
      items: this.$store.state.dev.charging_history,
      fields: [{ key: 'timestamp', label: 'Timestamp' }, 'Charging_Station', 'AGV_Name', 'Start_Voltage'],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      filter: null,
      filterOn: [],
      charging_history: this.$store.state.dev.charging_history
    }
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered (filteredItems) {
      this.charging_history = filteredItems
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>
