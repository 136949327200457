import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: 'AIzaSyDzKEqTYoEkJdAWOUIoaslgVJpIURfMzgE',
//   authDomain: 'xnergy-cloud-testing-0.firebaseapp.com',
//   databaseURL: 'https://xnergy-cloud-testing-0.firebaseio.com',
//   projectId: 'xnergy-cloud-testing-0',
//   storageBucket: 'xnergy-cloud-testing-0.appspot.com',
//   messagingSenderId: '733065701368',
//   appId: '1:733065701368:web:fd9e038e784848af58980d',
//   measurementId: 'G-F8DJHZ2MVE'
// }
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDMIe-gLvNmyB_uXg3AfFd-MMM16rNbD3E',
  authDomain: 'xnergy-cloud.firebaseapp.com',
  databaseURL: 'https://xnergy-cloud.firebaseio.com',
  projectId: 'xnergy-cloud',
  storageBucket: 'xnergy-cloud.appspot.com',
  messagingSenderId: '620527745423',
  appId: '1:620527745423:web:143298e4c2f459d1e4f3fa',
  measurementId: 'G-C2RJVYGD9G'
}

// Initialize Firebase
const firebase_app = firebase.initializeApp(firebaseConfig)

firebase_app.firestore().enablePersistence()
  .then(
    function () {
      // console.log('firestore enablePersistence successful')
    }
  )
  .catch(function (err) {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })
export default firebase_app
