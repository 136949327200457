export default
[
  {
    Date: '2020-04-03',
    Time: '8:02:00',
    Charging_Station: 'Station_1',
    AGV_Name: '3321',
    Average_Voltage: 25.94,
    Start_Voltage: 24.98,
    End_Voltage: 26.3,
    Start_Current: 14.83,
    End_Current: 21.63,
    Duration: 60,
    Average_Temperature: 31
  },
  {
    Date: '2020-05-03',
    Time: '4:16:47',
    Charging_Station: 'Station_2',
    AGV_Name: '3321',
    Average_Voltage: 23.45,
    Start_Voltage: 26.95,
    End_Voltage: 26.29,
    Start_Current: 17.83,
    End_Current: 20.89,
    Duration: 60,
    Average_Temperature: 35
  },
  {
    Date: '2020-02-14',
    Time: '14:51:06',
    Charging_Station: 'Station_3',
    AGV_Name: 'AGV_2',
    Average_Voltage: 24.89,
    Start_Voltage: 23.69,
    End_Voltage: 23.67,
    Start_Current: 12.24,
    End_Current: 13.95,
    Duration: 60,
    Average_Temperature: 34
  },
  {
    Date: '2020-04-24',
    Time: '11:00:55',
    Charging_Station: 'Station_4',
    AGV_Name: 'AGV_2',
    Average_Voltage: 23.22,
    Start_Voltage: 26.24,
    End_Voltage: 26.85,
    Start_Current: 15.9,
    End_Current: 31.55,
    Duration: 60,
    Average_Temperature: 36
  },
  {
    Date: '2020-04-08',
    Time: '10:08:41',
    Charging_Station: 'Station_5',
    AGV_Name: 'AGV_3',
    Average_Voltage: 26.21,
    Start_Voltage: 24.07,
    End_Voltage: 26.9,
    Start_Current: 30.52,
    End_Current: 24.8,
    Duration: 60,
    Average_Temperature: 30
  },
  {
    Date: '2020-03-26',
    Time: '3:10:35',
    Charging_Station: 'Station_6',
    AGV_Name: 'AGV_3',
    Average_Voltage: 23.49,
    Start_Voltage: 26.23,
    End_Voltage: 23.36,
    Start_Current: 30.74,
    End_Current: 10.12,
    Duration: 60,
    Average_Temperature: 46
  },
  {
    Date: '2020-03-08',
    Time: '3:49:32',
    Charging_Station: 'Station_7',
    AGV_Name: 'AGV_4',
    Average_Voltage: 26.65,
    Start_Voltage: 25.72,
    End_Voltage: 25.3,
    Start_Current: 24.59,
    End_Current: 23.16,
    Duration: 60,
    Average_Temperature: 39
  },
  {
    Date: '2020-04-01',
    Time: '18:08:31',
    Charging_Station: 'Station_8',
    AGV_Name: 'AGV_4',
    Average_Voltage: 25.77,
    Start_Voltage: 25.98,
    End_Voltage: 25.17,
    Start_Current: 29.12,
    End_Current: 17.29,
    Duration: 60,
    Average_Temperature: 42
  },
  {
    Date: '2020-01-18',
    Time: '1:23:16',
    Charging_Station: 'Station_9',
    AGV_Name: 'AGV_5',
    Average_Voltage: 25.28,
    Start_Voltage: 25.26,
    End_Voltage: 26.46,
    Start_Current: 26.21,
    End_Current: 39.48,
    Duration: 60,
    Average_Temperature: 49
  },
  {
    Date: '2020-02-02',
    Time: '2:48:39',
    Charging_Station: 'Station_10',
    AGV_Name: 'AGV_5',
    Average_Voltage: 25.69,
    Start_Voltage: 26.47,
    End_Voltage: 23.18,
    Start_Current: 30.33,
    End_Current: 17.7,
    Duration: 60,
    Average_Temperature: 47
  },
  {
    Date: '2020-04-16',
    Time: '9:02:26',
    Charging_Station: 'Station_11',
    AGV_Name: 'AGV_6',
    Average_Voltage: 25.25,
    Start_Voltage: 23.76,
    End_Voltage: 24.26,
    Start_Current: 32.93,
    End_Current: 20.35,
    Duration: 60,
    Average_Temperature: 39
  },
  {
    Date: '2020-05-19',
    Time: '7:23:31',
    Charging_Station: 'Station_12',
    AGV_Name: 'AGV_6',
    Average_Voltage: 23.41,
    Start_Voltage: 23.74,
    End_Voltage: 26.58,
    Start_Current: 18.32,
    End_Current: 32.3,
    Duration: 60,
    Average_Temperature: 48
  },
  {
    Date: '2020-04-10',
    Time: '14:15:38',
    Charging_Station: 'Station_12',
    AGV_Name: 'AGV_7',
    Average_Voltage: 26.55,
    Start_Voltage: 24.96,
    End_Voltage: 24.77,
    Start_Current: 19.22,
    End_Current: 22.44,
    Duration: 60,
    Average_Temperature: 33
  },
  {
    Date: '2020-05-12',
    Time: '22:03:31',
    Charging_Station: 'Station_11',
    AGV_Name: 'AGV_7',
    Average_Voltage: 23.47,
    Start_Voltage: 24.06,
    End_Voltage: 24.88,
    Start_Current: 37.23,
    End_Current: 10.98,
    Duration: 60,
    Average_Temperature: 46
  },
  {
    Date: '2020-01-02',
    Time: '3:58:14',
    Charging_Station: 'Station_10',
    AGV_Name: 'AGV_8',
    Average_Voltage: 25.32,
    Start_Voltage: 25.58,
    End_Voltage: 23.57,
    Start_Current: 22.25,
    End_Current: 26.82,
    Duration: 60,
    Average_Temperature: 49
  },
  {
    Date: '2020-05-19',
    Time: '3:05:59',
    Charging_Station: 'Station_9',
    AGV_Name: 'AGV_8',
    Average_Voltage: 25.59,
    Start_Voltage: 23.54,
    End_Voltage: 26.52,
    Start_Current: 24.61,
    End_Current: 35.83,
    Duration: 60,
    Average_Temperature: 41
  },
  {
    Date: '2020-01-09',
    Time: '21:28:51',
    Charging_Station: 'Station_8',
    AGV_Name: 'AGV_9',
    Average_Voltage: 23.16,
    Start_Voltage: 24.18,
    End_Voltage: 25.9,
    Start_Current: 22.79,
    End_Current: 40.12,
    Duration: 60,
    Average_Temperature: 44
  },
  {
    Date: '2020-05-17',
    Time: '18:48:08',
    Charging_Station: 'Station_7',
    AGV_Name: 'AGV_9',
    Average_Voltage: 23.69,
    Start_Voltage: 24.4,
    End_Voltage: 26.3,
    Start_Current: 13.17,
    End_Current: 40.48,
    Duration: 60,
    Average_Temperature: 50
  }
]
