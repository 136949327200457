import agvDetail from '../data/agvDetail'
import currentData from '../data/currentData'
import historicalData from '../data/historicalData'
import statusOfAgv from '../data/statusOfAgvReport'
import chargerDetails from '../data/chargerDetails'

export default {
  agvDetail,
  currentData,
  historicalData,
  statusOfAgv,
  chargerDetails,
  charging_history: [],
  tx: {
    num_total: 1,
    num_standby: 1,
    num_charging: 0,
    num_error: 0,
    num_offline: 0
  }
}
