export default [
  {
    AGV_Name: 'AGV_1',
    Voltage: 25.8,
    Current: 27.24,
    Cycle_Count: 10,
    Age: 2,
    SOH: 12
  },
  {
    AGV_Name: 'AGV_2',
    Voltage: 23.57,
    Current: 27.34,
    Cycle_Count: 89,
    Age: 12,
    SOH: 63
  },
  {
    AGV_Name: 'AGV_3',
    Voltage: 26.33,
    Current: 25.97,
    Cycle_Count: 34,
    Age: 13,
    SOH: 34
  },
  {
    AGV_Name: 'AGV_4',
    Voltage: 24.1,
    Current: 22.4,
    Cycle_Count: 31,
    Age: 8,
    SOH: 24
  },
  {
    AGV_Name: 'AGV_5',
    Voltage: 25.26,
    Current: 19.84,
    Cycle_Count: 17,
    Age: 12,
    SOH: 63
  },
  {
    AGV_Name: 'AGV_6',
    Voltage: 24.73,
    Current: 20.86,
    Cycle_Count: 16,
    Age: 16,
    SOH: 41
  },
  {
    AGV_Name: 'AGV_7',
    Voltage: 24.23,
    Current: 32.66,
    Cycle_Count: 88,
    Age: 6,
    SOH: 54
  },
  {
    AGV_Name: 'AGV_8',
    Voltage: 26.94,
    Current: 28.56,
    Cycle_Count: 75,
    Age: 0,
    SOH: 6
  },
  {
    AGV_Name: 'AGV_9',
    Voltage: 24.26,
    Current: 39.08,
    Cycle_Count: 21,
    Age: 11,
    SOH: 71
  },
  {
    AGV_Name: 'AGV_10',
    Voltage: 25.31,
    Current: 22.33,
    Cycle_Count: 19,
    Age: 11,
    SOH: 7
  }
]
