<template>
  <svg
    :viewBox="`0 0 ${UNITS} ${UNITS}`"
    :height="size || '100%'"
    :width="size || '100%'"
  >
    <g :transform="`rotate(${rotate},${UNITS/2},${UNITS/2}) ${reverse ? 'scale(1,-1) translate(0, -' + UNITS +')' : ''}`">
      <circle
        :cx="UNITS/2"
        :cy="UNITS/2"
        :r="getRadius()"
        :stroke="stroke"
        :stroke-width="getStrokeWidth(strokeWidth)"
        fill="none"
        :stroke-dasharray="getLengths()"
      />
      <path
        :d="describeArc(UNITS/2, UNITS/2, getRadius(), 0, activeEnd())"
        fill="none"
        :stroke="activeStroke"
        :stroke-width="getStrokeWidth(activeWidth)"
        :stroke-dasharray="getLengths()"
      />
    </g>
    <text fill="currentColor" x="50%" y="50%" text-anchor="middle" dominant-baseline="middle">
      {{text}}
    </text>
    <text v-if="typeof (activeCount) === 'number'" fill="currentColor" x="50%" y="70%" text-anchor="middle" dominant-baseline="middle">
      {{textNumber}}
    </text>
    <b-icon x="45%" y="30%" :icon="iconText"></b-icon>
  </svg>
</template>

<script>
// https://github.com/snirp/vue-circle-counter/blob/master/README.md
export default {
  beforeCreate () {
    // Arbitrary dimensions of SVG to set up user-space units
    this.UNITS = 200
  },
  props: {
    size: {
      type: String,
      default: '100%'
    },
    text: {
      type: String,
      default: ''
    },
    textNumber: {
      default: ''
    },
    dashCount: {
      type: Number,
      default: 100
    },
    activeCount: {
      default: 0
    },
    strokeWidth: {
      type: Number,
      default: 20
    },
    activeWidth: {
      type: Number,
      default: 20
    },
    stroke: {
      type: String,
      default: 'lightgrey'
    },
    activeStroke: {
      type: String,
      default: 'lightgreen'
    },
    dashSpacing: {
      type: Number,
      default: 0
    },
    rotate: {
      type: Number,
      default: -90
    },
    reverse: {
      type: Boolean,
      default: false
    },
    iconText: {
      type: String,
      default: ''
    }
  },
  methods: {
    // Stroke is provided as a percentage of the radius, translate into user space units
    getStrokeWidth (stroke) {
      return stroke * this.UNITS / 200
    },

    // Determine the 'end' angle of the path for the active dashes in degrees.
    activeEnd () {
      if (this.activeCount === 0) {
        return 0
      }
      return 360 * (this.activeCount * this.dashPerc() + (this.activeCount - 1) * this.spacePerc())
    },

    // An array of the length of the dash & the length of the space between dashes
    getLengths () {
      return [2 * Math.PI * this.getRadius() * this.dashPerc(), 2 * Math.PI * this.getRadius() * this.spacePerc()]
    },

    // The space beween dashes as a percentage of the total length
    spacePerc () {
      return this.dashSpacing / this.dashCount
    },

    // The length of a dash as a percentage of the total length
    dashPerc () {
      return (1 - this.dashSpacing) / this.dashCount
    },

    // Radius of the circle arc
    getRadius () {
      return (this.UNITS - Math.max(this.getStrokeWidth(this.strokeWidth), this.getStrokeWidth(this.activeWidth))) / 2
    },

    // SVG path definition requires points in cartesian space
    polarToCartesian (cx, cy, radius, degrees) {
      const radians = degrees * Math.PI / 180.0
      return {
        x: cx + (radius * Math.cos(radians)),
        y: cy + (radius * Math.sin(radians))
      }
    },

    // Path definition for circular arc
    describeArc (cx, cy, radius, startDegrees, endDegrees) {
      const start = this.polarToCartesian(cx, cy, radius, startDegrees)
      const end = this.polarToCartesian(cx, cy, radius, endDegrees)

      const largeArc = (Math.abs(endDegrees - startDegrees) < 180) ? 0 : 1
      const sweep = (endDegrees < startDegrees) ? 0 : 1

      return `M${start.x} ${start.y} A${radius} ${radius} 0 ${largeArc} ${sweep} ${end.x} ${end.y}`
    }
  }
}
</script>
