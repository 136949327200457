<template>
	<b-container>
		<b-form>
			<b-form-group label="Battery Information">
				<b-row cols="1" cols-lg="2">
					<b-col>
						<b-input-group prepend="Maximum Energy Capacity (Ah)" class="mt-3">
							<b-form-input v-model="maxCapacity" type="number" min="0" step="0.1" number @change="onChange" :state="maxCapacityState()">
							</b-form-input>
						</b-input-group>
						<b-form-invalid-feedback :state="maxCapacityState()">Enter a number larger than 0</b-form-invalid-feedback>
					</b-col>
					<b-col>
						<b-input-group prepend="Available Capacity (Ah)" class="mt-3">
							<b-form-input v-model="availCapacity" type="number" :min="lowCapacity" :max="runTime" number @change="onChange" :state="availCapacityState()">
							</b-form-input>
						</b-input-group>
						<b-form-invalid-feedback :state="availCapacityState()">Enter a number between {{lowCapacity}} and {{runTime}}.</b-form-invalid-feedback>
					</b-col>
				</b-row>
				<b-row cols="1" cols-lg="2">
					<b-col>
						<b-input-group prepend="20% Low State Capacity (Ah)" class="mt-3">
							<b-form-input v-model="lowCapacity" readonly number>
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col>
						<b-input-group prepend="70% Cycle Life Consideration (Ah)" class="mt-3">
							<b-form-input v-model="runTime" readonly number>
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-input-group prepend="Useful Battery Capacity (Ah)" class="mt-3">
							<b-form-input v-model="usefulCapacity" readonly number>
							</b-form-input>
						</b-input-group>
			</b-form-group>
			<hr>
			<b-form-group label="Machine Consumption Information">
				<b-row cols="1" cols-lg="2">
					<b-col>
						<b-input-group prepend="Consumption on standby (A)" class="mt-3">
							<b-form-input v-model="standbyConsumption" type="number" min="0" step="0.1" number @change="onChange" :state="standbyConsumptionState()">
							</b-form-input>
						</b-input-group>
						<b-form-invalid-feedback :state="standbyConsumptionState()">Enter a number larger than 0</b-form-invalid-feedback>
					</b-col>
					<b-col>
						<b-input-group prepend="Consumption while on load (A)" class="mt-3">
							<b-form-input v-model="loadConsumption" type="number" min="0" number @change="onChange" :state="loadConsumptionState()">
							</b-form-input>
						</b-input-group>
						<b-form-invalid-feedback :state="loadConsumptionState()">Enter a number larger than 0</b-form-invalid-feedback>
					</b-col>
				</b-row>
				<b-row cols="1" cols-lg="2">
					<b-col>
						<b-input-group prepend="Percentage time on standby (%)" class="mt-3">
							<b-form-input v-model="standbyPercentage" type="number" min="0" max="100" number @change="onChange" :state="standbyPercentageState()">
							</b-form-input>
						</b-input-group>
						<b-form-invalid-feedback :state="standbyPercentageState()">Enter a number between 1 and 100</b-form-invalid-feedback>
					</b-col>
					<b-col>
						<b-input-group prepend="Percentage time on load (%)" class="mt-3">
							<b-form-input v-model="loadPercentage" readonly number>
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
			</b-form-group>
			<hr>
			<b-form-group label="Charging Information">
				<b-input-group prepend="Charger output current (A)" class="mt-3">
					<b-form-input v-model="outputCurrent" type="number" min="0" number @change="onChange" :state="outputCurrentState()">
					</b-form-input>
				</b-input-group>
				<b-form-invalid-feedback :state="outputCurrentState()">Enter a number larger than 0</b-form-invalid-feedback>
				<b-input-group prepend="Charging rate for every 3 minutes (%)" class="mt-3">
					<b-form-input v-model="chargingRate" type="number" min="0" number @change="onChange" :state="chargingRateState()">
					</b-form-input>
				</b-input-group>
				<b-form-invalid-feedback :state="chargingRateState()">Enter a number between 1 and 100</b-form-invalid-feedback>
				<b-input-group prepend="Number of charges (Per Hour)" class="mt-3">
					<b-form-input v-model="numOfChargePerHour" type="number" min="0" number @change="onChange">
					</b-form-input>
				</b-input-group>
			</b-form-group>
			<hr>
			<b-form-group label="State of Charge" label-for="label_5" label-align="left">
				<b-progress :max="100" variant="success">
					<b-progress-bar :value="stateOfCharge" :label="`${(stateOfCharge).toFixed(2)}%`"></b-progress-bar>
				</b-progress>
				<b-input-group prepend="Time to full charge (Hours)" class="mt-3">
					<b-form-input v-model="timeCharge" readonly>
					</b-form-input>
				</b-input-group>
			</b-form-group>
			<hr>
			<b-card header="Runtime till Low 20% battery" class="text-center">
				<b-card-text>On standby without charge (Hours): {{uptimeStandby}}</b-card-text>
				<b-card-text>With duty cycles without charge (Hours): {{uptime2}}</b-card-text>
				<b-card-text>With 1 hour period of intermittent charging (Hours): {{uptime3}}</b-card-text>
				<b-card-text>Continuous uptime until battery requires full charge (Hours): {{uptime4}}</b-card-text>
			</b-card>
		</b-form>
	</b-container>
</template>
<script>
export default {
  name: 'test',
  data () {
    return {
      maxCapacity: 0,
      availCapacity: 0,
      runTime: 0,
      uptimeStandby: 0,
      uptime2: 0,
      uptime3: 0,
      uptime4: 0,
      numOfChargePerHour: 1,
      standbyConsumption: 0,
      standbyPercentage: 100,
      loadConsumption: 0,
      loadPercentage: 0,
      lowCapacity: 0,
      usefulCapacity: 0,
      outputCurrent: 0,
      chargingRate: 0,
      stateOfCharge: 0,
      timeCharge: 0
    }
  },
  methods: {
    onChange: function () {
      this.loadPercentage = 100 - this.standbyPercentage
      this.runTime = 0.7 * this.maxCapacity
      this.lowCapacity = 0.2 * this.maxCapacity
      this.usefulCapacity = this.runTime - this.lowCapacity

      const chargingMinutes = 3
      const standbyPercentage = this.standbyPercentage / 100
      const chargingRate = this.chargingRate / 100
      const availToRun = this.runTime - this.availCapacity
      const availToEmpty = this.availCapacity - this.lowCapacity
      const usedCapPerHour = this.standbyConsumption * standbyPercentage + this.loadConsumption * (1 - standbyPercentage)
      const chargingCapPerHour = this.outputCurrent * (chargingMinutes / 60) * this.numOfChargePerHour

      this.stateOfCharge = (this.availCapacity / this.maxCapacity) * 100
      this.timeCharge = availToRun / (this.runTime * chargingRate * 20)

      this.uptimeStandby = availToEmpty / this.standbyConsumption
      this.uptime2 = availToEmpty / (usedCapPerHour)
      this.uptime3 = availToEmpty / (usedCapPerHour - (this.runTime * chargingRate * this.numOfChargePerHour))
      this.uptime4 = availToEmpty / (usedCapPerHour - chargingCapPerHour)

      this.timeCharge = this.timeCharge.toFixed(2)
      this.runTime = this.runTime.toFixed(2)
		  this.lowCapacity = this.lowCapacity.toFixed(2)
      this.usefulCapacity = this.usefulCapacity.toFixed(2)

      this.uptimeStandby = this.uptimeStandby.toFixed(2)
      this.uptime2 = this.uptime2.toFixed(2)
      this.uptime3 = this.uptime3.toFixed(2)
      this.uptime4 = this.uptime4.toFixed(2)

	    // check for errors
	    if (!this.availCapacityState) {
	      this.stateOfCharge = 0
		    this.timeCharge = 'Not Applicable'
		    this.uptimeStandby = 'Not Applicable'
		    this.uptime2 = 'Not Applicable'
		    this.uptime3 = 'Not Applicable'
		    this.uptime4 = 'Not Applicable'
	    }
	    if (!this.standbyPercentageState() || !this.standbyConsumptionState()) {
        this.uptimeStandby = 'Not Applicable'
		    this.uptime2 = 'Not Applicable'
		    this.uptime3 = 'Not Applicable'
		    this.uptime4 = 'Not Applicable'
	    }
	    if (!this.outputCurrentState() || !this.chargingRateState()) {
	        this.timeCharge = 'Not Applicable'
			    this.uptime3 = 'Not Applicable'
			    this.uptime4 = 'Not Applicable'
	    }
	    if (this.uptime3 <= 0 || this.uptime3 >= 24) {
	        this.uptime3 = '24/7 Uptime'
	    }
	    if (this.uptime4 <= 0 || this.uptime4 >= 24) {
        this.uptime4 = '24/7 Uptime'
	    }
    },
	  maxCapacityState: function () {
	    if (this.maxCapacity <= 0) {
	      return false
	    } else return true
	  },
    availCapacityState: function () {
      if (this.availCapacity > this.runTime || this.availCapacity < this.lowCapacity) {
        return false
      } else return true
    },
	  standbyConsumptionState: function () {
	    if (this.standbyConsumption <= 0) {
	      return false
	    } else return true
	  },
	  standbyPercentageState: function () {
	    if (this.standbyPercentage <= 0 || this.standbyPercentage > 100) {
	      return false
	    } else return true
	  },
	  loadConsumptionState: function () {
	    if (this.loadConsumption <= 0) {
	      return false
	    } else return true
	  },
	  outputCurrentState: function () {
	    if (this.outputCurrent <= 0) {
	      return false
	    } else return true
	  },
	  chargingRateState: function () {
      if (this.chargingRate <= 0 || this.chargingRate > 100) {
        return false
      } else return true
	  }
  }
}
</script>
<style scoped>
</style>
