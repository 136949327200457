import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import firebase from '@/firebase_init'
Vue.use(Vuex)

const device_module = {
  namespaced: true,
  state,
  mutations: {
    tx_num_update_charging (state, charging) {
      if (charging) {
        state.tx.num_standby = 0
        state.tx.num_charging = 1
      } else {
        state.tx.num_standby = 1
        state.tx.num_charging = 0
      }
    },
    tx_num_update_error (state, is_error) {
      if (is_error) {
        state.tx.num_error = 1
        state.tx.num_charging = 0
        state.tx.num_standby = 0
      } else {
        state.tx.num_error = 0
        // state.tx.num_charging = 0
        // state.tx.num_standby = 0
      }
    },
    add_charging_history (state, record) {
      state.charging_history.push(record)
    }

  },
  actions: {
    add_charging_history ({ commit, dispatch, state }, record) {
      commit('add_charging_history', record)
    }
  },
  getters: {
    tx_num_standby: (state) => {
      return state.tx.num_total - state.tx.num_charging - state.tx.num_error - state.tx.num_offline
    },
    charging_history: (state) => {
      return state.charging_history
    }
  }
}
const web_auth_module = {
  namespaced: true,
  state: {
    token: localStorage.getItem('access_token') || null
  },
  mutations: {
    retrieveToken (state, token) {
      state.token = token
    },
    destroyToken (state) {
      state.token = null
    }
  },
  getters: {
    loggedIn: (state) => {
      return state.token !== null
    }
  },
  actions: {
    destroyToken (context) {
      if (context.getters.loggedIn) {
        firebase.auth().signOut()
        localStorage.removeItem('access_token')
        context.commit('destroyToken')
      }
    },
    retrieveToken (context, credentials) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(credentials.username, credentials.password)
          .then(
            () => {
              const response = {
                data: {
                  user: firebase.auth().currentUser
                }
              }
              response.data.user.getIdTokenResult().then(
                (token) => {
                  localStorage.setItem('access_token', token.token)
                  context.commit('retrieveToken', token.token)
                  console.log(response)
                  resolve(response)
                }
              )
            }
          ).catch(function (error) {
          // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            // [START_EXCLUDE]
            if (errorCode === 'auth/wrong-password') {
              alert('Wrong password.')
            } else {
              alert(errorMessage)
            }
            console.log(error)
            reject(error)
          // [END_EXCLUDE]
          })
      })
    }
  }
}
export default new Vuex.Store({
  modules: {
    dev: device_module,
    web_auth: web_auth_module
  }
})
