<template>
  <div class="text-center">
    <h2>AGV {{ agvName }} Profile Information</h2>
    <agv-tabs :agv-name=agvName></agv-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AgvTabs from '../components/AgvTabs'

export default {
  components: {
    AgvTabs
  },
  data () {
    return {
      agvName: ''
    }
  },
  computed: {
    ...mapState([
      'agvDetail'
    ])
  },
  mounted () {
    this.agvName = this.$route.params.id
  }
}
</script>
