export default [
  {
    AGV_Name: '3321',
    Robot_Information: {
      'Serial Number': 'CLIE-760-3321',
      'Manufacturer Name': 'ASTI',
      'Manufacture Year': 2019,
      'Model Number': 'EASYBOT41',
      'AGV Weight without Battery': '110Kg',
      'AGV Weight with Battery': '200Kg',
      'Nominal Capacity': '350N/0Kg',
      'V min / V max': '0.3m/s / 0.8 m/s',
      'Voltage AUTO': '24V'
    },
    Battery_Data: {
      'Manufacture Name': 'EXIDE MARINE & LEISURE',
      'Module Voltage': '12V',
      'Module Current': 13.35,
      'Relative SOC': '94 %',
      'Absolute SOC': '100 %',
      'Cycle Count': 7,
      'Charging Current Limit': '40 A',
      'Charging Voltage Limit': '13.9 V',
      'Full Charge Capacity': '1600 Wh',
      'Last Install Date': '31/05/2020'
    },
    Receiver_Information: {
      'Model Number': 'RX2409BE-0A00',
      'Serial Number': '04180001',
      'Voltage Rating': '24 V',
      'Manufactured Date': '15/05/2020',
      'Hardware Version': '3.1.5',
      'Software Version': '1.0.1',
      'Install Date': '31/05/2020'
    }
  }, {
    AGV_Name: 'AGV_2',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-14244',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 15,
      'Module Current': 13
    }
  }, {
    AGV_Name: 'AGV_3',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-15151',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 24.22,
      'Module Current': 11.11
    }
  }, {
    AGV_Name: 'AGV_4',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_5',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_6',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_7',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_8',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_9',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_10',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_11',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }, {
    AGV_Name: 'AGV_12',
    Module_ID: {
      'Serial Number': '0A20-1516-02R-522003-11008',
      'Manufacturer Data': '2014 NEC Energy Solutions Inc'
    },
    Smart_Data: {
      'Module Voltage': 13.35,
      'Module Current': 13.35
    }
  }
]
