<template>
  <b-container class="text-center">
    <b-row v-for="i in rowCount" :key="i">
      <b-col>
        <span v-for="(item,index) in itemCountInRow(i)" :key="index">
			  <b-container>
					<b-row>
						<b-col cols="12" lg="3">
						<b-input-group prepend="Transmitter:" class="mt-3">
								<b-input-group-append>
									<b-button variant="alert-primary">
									<router-link :to="`/charger-report/${item.Charging_Station}`"
                  >{{ item.Charging_Station }}</router-link>
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-col>
						<b-col cols="12" lg="3">
						<b-input-group prepend="Status:" class="mt-3">
								<b-form-input :class="colorVariant(status_text)" v-model="status_text" readonly> </b-form-input>
							</b-input-group>
						</b-col>
						<b-col cols="12" lg="3">
							<b-input-group prepend="Receiver ID:" class="mt-3">
								<b-form-input v-model="item.RCU_ID" readonly> </b-form-input>
							</b-input-group>
						</b-col>
						<b-col cols="12" lg="3">
							<b-input-group prepend="AGV ID:" class="mt-3">
								<b-input-group-append>
									<b-button variant="alert-primary">
									<router-link :to="`/agv-report/${item.AGV_ID}`"
                  >AGV {{ item.AGV_ID }}</router-link>
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="12" md="6">
							<b-input-group prepend="Error Code" class="mt-3">
								<b-form-input v-model="error_code_text" readonly></b-form-input>
							</b-input-group>
						</b-col>
						<b-col cols="12" md="6">
						 <b-input-group prepend="Charging for " class="mt-3" append="Seconds">
								<b-form-input v-model="charging_duration" readonly></b-form-input>
						 </b-input-group>
						</b-col>
					</b-row>
				</b-container> <!-- Info Bar -->
          <b-row>
            <b-col md="6" cols="12">
                <CircleCounter
                    class="circle_counter"
                    text="Current"
                    icon-text="type-italic"
                    :text-number="battery_current.toFixed(1) + ' A'"
                    :active-count="battery_current/max_current*100"
                    :active-stroke="activeStrokeColor(battery_current)"
                    :size="(window.width < 570) ? '90%' : '70%'"
                ></CircleCounter>
            </b-col>
            <b-col md="6" cols="12">
                <CircleCounter
                    text="Voltage"
                    class="circle_counter"
                    icon-text="battery-charging"
                    :text-number="battery_voltage.toFixed(1)+' V'"
                    :active-count="battery_voltage/max_voltage*100"
                    :active-stroke="activeStrokeColor(battery_voltage)"
                    :size="(window.width < 570) ? '90%' : '70%'"
                ></CircleCounter>
            </b-col>
          </b-row>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CircleCounter from '@/components/CircleCounter'

export default {
  components: { CircleCounter },
  props: {
    chargingDataArray: Array,
    battery_voltage: Number,
    battery_current: Number,
    max_current: Number,
    max_voltage: Number,
    min_voltage: Number,
    charger_status: Number,
    rx_error_code_hi: Number
  },
  data () {
    return {
      itemsPerRow: 1,
      items: this.chargingDataArray,
      charging_duration: 0,
      soc: 0,
      window: {
        width: 0,
        height: 0
      }
    }
  },
  watch: {
    charger_status: function (var_new, var_old) {
      if (var_old === 0 && var_new === 1) { // starting charging,
        this.charging_timer_id = setInterval(() => {
          this.charging_duration = this.charging_duration + 1
        }, 1000)
        this.$store.commit('dev/tx_num_update_charging', true)
        const timestamp = new Date()
        this.$store.dispatch('dev/add_charging_history',
          {
            timestamp: timestamp,
            Charging_Station: 'Station_1',
            AGV_Name: '3321',
            Start_Voltage: this.battery_voltage.toFixed(2)
          })
      } else if (var_old === 1 && var_new === 0) { // stopping charging
        clearInterval(this.charging_timer_id)
        this.charging_duration = 0
        this.$store.commit('dev/tx_num_update_charging', false)
      }
    }
  },
  computed: {
    rowCount: function () {
      return Math.ceil(this.items.length / this.itemsPerRow)
    },
    status_text: function () {
      return this.status2text(this.charger_status)
    },
    error_code_text: function () {
      return this.rx_error_code_2_text(this.rx_error_code_hi)
    }
  },
  methods: {
    status2text: function (status) {
      let status_text = ''
      switch (status) {
        case 0:
          status_text = 'Standby'
          break
        case 1:
          status_text = 'In Operation'
          break
        default:
          status_text = 'Offline'
          break
      }
      if (this.rx_error_code_hi !== 0) {
        status_text = 'Error'
      }
      return status_text
    },
    rx_error_code_2_text: function (error_code_) {
      let error_code_text = ''
      switch (error_code_) {
        case 0: {
          error_code_text = '-'
          this.$store.commit('dev/tx_num_update_error', false)
          break
        }
        case 1: {
          error_code_text = 'RX unit is too far'
          this.$store.commit('dev/tx_num_update_error', true)
          break
        }
        case 0x0002: {
          error_code_text = 'Foreign Object Detected'
          this.$store.commit('dev/tx_num_update_error', true)
          break
        }
        default: {
          error_code_text = '-'
        }
      }
      return error_code_text
    },
    itemCountInRow: function (index) {
      return this.items.slice(
        (index - 1) * this.itemsPerRow,
        index * this.itemsPerRow
      )
    },
    colorVariant: function (status) {
      let color = ''
      switch (status) {
        case 'In Operation':
          color = 'text-success'
          break
        case 'Standby':
          color = 'text-primary'
          break
        case 'Powered Off':
          color = 'text-secondary'
          break
        case 'Error During Operation':
          color = 'text-warning'
          break
        case 'Error':
        case 'Self-Check Error':
          color = 'text-danger'
          break
      }
      return color
    },
    activeStrokeColor: function (x) {
      let color = ''
      if (typeof (x) !== 'number') {
        return color
      } else {
        if (x <= 25) {
          color = 'red'
        } else if (x > 25 && x <= 75) {
          color = 'yellow'
        } else if (x > 75) {
          color = 'lightgreen'
        }
      }
      return 'lightgreen'
    },
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.router-text-color {
  /*color: #000000;*/
}

.circle_counter {
  margin-left: 40px;
  margin-right: 40px;
  padding: 20px;
}

</style>
