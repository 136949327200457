<template>
  <div v-if="agvName">
    <b-card no-body>
      <b-tabs pills card>
        <b-tab title="Robot Information" active><b-card-text>
          <b-row  v-for="(value,key,index) in filtered.Robot_Information" :key="index">
            <b-col v-if="index%2===0"> {{ key }} :  {{ value }}</b-col>
            <b-col v-else></b-col>
            <b-col v-if="index%2===1"> {{ key }} : {{ value}}</b-col>
            <b-col v-else></b-col>
          </b-row>
        </b-card-text></b-tab>
        <b-tab title="Receiver Information" ><b-card-text>
          <b-row v-for="(value,key,index) in filtered.Receiver_Information" :key="index">
            <b-col v-if="index%2===0"> {{ key }} :  {{ value }}</b-col>
            <b-col v-else></b-col>
            <b-col v-if="index%2===1"> {{ key }} : {{ value}}</b-col>
            <b-col v-else></b-col>
          </b-row>
        </b-card-text></b-tab>
        <b-tab title="Battery Data" ><b-card-text>
          <b-row v-for="(value,key,index) in filtered.Battery_Data" :key="index">
            <b-col v-if="index%2===0"> {{ key }} :  {{ value }}</b-col>
            <b-col v-else></b-col>
            <b-col v-if="index%2===1"> {{ key }} : {{ value}}</b-col>
            <b-col v-else></b-col>
          </b-row>
        </b-card-text></b-tab>
        <b-tab title="Wireless Charging Data"><b-card-text>
          <DataTable :data-array="filterData"></DataTable>
        </b-card-text></b-tab>
<!--        <b-tab title="SOC Graph"><b-card-text>-->
<!--          <scatter-graph-soc></scatter-graph-soc>-->
<!--        </b-card-text></b-tab>-->
        <b-tab title="Alarm Settings"><b-card-text>
          <b-row v-for="(value,key,index) in alarmSettings" :key="index">
            <b-col v-if="index%2===0"> {{ key }} :  {{ value }}</b-col>
            <b-col v-else></b-col>
            <b-col v-if="index%2===1"> {{ key }} : {{ value}}</b-col>
            <b-col v-else></b-col>
          </b-row>
        </b-card-text></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>

import agvDetail from '../data/agvDetail'
import DataTable from '../components/DataTable'
// import ScatterGraphSoc from '../components/ScatterGraphSoc'

export default {
  components: {
    // ScatterGraphSoc,
    DataTable
  },
  props: {
    agvName: String
  },
  data () {
    return {
      agvInfo: agvDetail,
      alarmSettings: {
        'Voltage Warning Max limit': '27 V',
        'Voltage Warning Min limit': '23 V',
        'Delta Voltage Alarm': '6 V',
        'Temperature Alarm Max limit': '60 °C',
        'SOC Alarm Min limit': '30 %',
        'SOH Alarm Min limit': '20 %',
        'Cycle Count Max limit': '150'
      }
    }
  },
  watch: {
    agvName: function () {
      console.log('watched')
    }
  },
  computed: {
    filtered: function () {
      const x = this.agvName
      return (this.agvInfo.filter(function (e) { return e.AGV_Name === x })[0])
    },
    filterData: function () {
      // Return historical data containing current page Agv Name
      const y = this.agvName
      const x = this.$store.state.dev.charging_history
      return (x.filter(function (e) { return e.AGV_Name === y }))
    }
  },
  mounted () {
    console.log('mount')
    console.log(this.agvName)
  }
}
</script>

<style scoped>

</style>
