<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab title="Transmitter Information" active><b-card-text>
          <b-row v-for="(value,key,index) in filtered.Transmitter_Information" :key="index">
            <b-col v-if="index%2===0"> {{ key }} :  {{ value }}</b-col>
            <b-col v-else></b-col>
            <b-col v-if="index%2===1"> {{ key }} : {{ value}}</b-col>
            <b-col v-else></b-col>
          </b-row>
        </b-card-text></b-tab>
<!--        <b-tab title="Historical Data"><b-card-text>-->
<!--         <DataTable :data-array="filterData()"></DataTable>-->
<!--        </b-card-text></b-tab>-->
<!--        <b-tab title="Charging Profile"><b-card-text>-->
<!--          <line-graph :charger-name="chargerName"></line-graph>-->
<!--        </b-card-text></b-tab>-->
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import TransmitterDetails from '@/data/transmitterDetails'
// import DataTable from '@/components/DataTable'
// import LineGraph from '@/components/LineGraph'

export default {
  // components: { LineGraph, DataTable },
  props: {
    chargerName: String
  },
  name: 'chargerTabs',
  data () {
    return {
      txInfo: TransmitterDetails
    }
  },
  methods: {
    filterData: function () {
      const y = this.chargerName
      const x = this.$store.state.dev.historicalData
      return (x.filter(function (e) { return e.Charging_Station === y }))
    }
  },
  computed: {
    filtered: function () {
      const x = this.chargerName
      return (this.txInfo.filter(function (e) { return e.Charging_Station === x })[0])
    }
  }
}
</script>

<style scoped>

</style>
