<template>
  <div id="app">
    <b-navbar toggleable="lg" type="light" variant="danger" :sticky="true">

      <b-navbar-brand >
        <router-link :to="{ name: 'home' }">
          <img class="logo" src="../../assets/white_logo_transparent_background.webp">
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto top_nav">
          <b-navbar-brand><router-link :to="{ name: 'home' }">Home</router-link></b-navbar-brand>
          <b-navbar-brand><router-link :to="{ name: 'tx' }">Dashboard</router-link></b-navbar-brand>
          <b-navbar-brand><router-link :to="{ name: 'Status' }">Status</router-link></b-navbar-brand>
          <b-navbar-brand><router-link :to="{ name: 'history' }">History</router-link></b-navbar-brand>
          <b-navbar-brand v-if="!loggedIn"><router-link :to="{ name: 'login' }">Login</router-link></b-navbar-brand>
          <b-navbar-brand v-if="loggedIn"><router-link :to="{ name: 'logout' }">Logout</router-link></b-navbar-brand>
        </b-navbar-nav>
      </b-collapse>

    </b-navbar>

    <transition name="fade" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
      <router-view class="py-3"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    loggedIn () {
      return this.$store.getters['web_auth/loggedIn']
    }
  }
}
</script>

<style lang="scss">
  @import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }

  .top_nav {
    display: flex;
    list-style: none;
    justify-content: flex-end;
  }

  .top_nav a  {
    color: 	#FFFFFF;
    padding: 0 25px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
  }
  .logo {
    height: 60px;
    margin: 0 0 0 5%;
  }
  // Auth Pages

  .login-heading {
    margin-bottom: 16px;
  }

  .form-control {
    margin-bottom: 24px;
  }

  .mb-more {
    margin-bottom: 42px;
  }

  .login-form {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }

  .login-input {
    width: 100%;
    font-size: 16px;
    padding: 12px 16px;
    outline: 0;
    border-radius: 3px;
    border: 1px solid lightgrey;
  }

  .server-error {
    margin-bottom: 12px;
    font-size: 16px;
    padding: 10px 16px;
    color: #a94442;
    background: #F3DEDE;
    border-radius: 4px;
  }

  .success-message {
    background-color: #dff0d8;
    color: #3c763d;
    margin-bottom: 12px;
    font-size: 16px;
    padding: 10px 16px;
    border-radius: 4px;
  }

  .form-error {
    font-size: 16px;
    color: #a94442;
  }

  .input-error {
    border: 1px solid red;
  }

  .page-wrapper {
    animation-duration: 0.2s;
  }

  // CSS Spinner

  .lds-ring-container {
    position: absolute;
    right: 50%;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    // margin: 6px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>
