import { render, staticRenderFns } from "./LandingPage.vue?vue&type=template&id=40bad047&scoped=true&"
var script = {}
import style0 from "./LandingPage.vue?vue&type=style&index=0&id=40bad047&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bad047",
  null
  
)

export default component.exports