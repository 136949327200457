<template>
  <div>
    <b-table striped hover
             :fields="fields"
             :items="dataArray"
             :per-page="perPage"
             :current-page="currentPage"
             responsive>
      <template v-slot:cell(AGV_Name)="data">
        <router-link :to="`/agv-report/${data.value}`">
          AGV {{ data.value }}
        </router-link>
      </template>
      <template v-slot:cell(Charging_Station)="data">
        <router-link :to="`/charger-report/${data.value}`">
          {{ data.value }}
        </router-link>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    dataArray: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      perPage: 5,
      currentPage: 1,
      fields: this.fieldArray()
    }
  },
  methods: {
    fieldArray () {
      // Returns an array of headers
      const field = []
      Object.keys(this.dataArray[0]).forEach(element => {
        field.push({
          key: element,
          sortable: true
        })
      })
      return field
    }
  },
  computed: {
    rows () {
      return this.dataArray.length
    }
  }
}
</script>
