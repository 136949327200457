export default [
  {
    Charging_Station: 'Station_1',
    Status: 'In Operation',
    RCU_ID: '04180001',
    AGV_ID: '3321',
    SOC: 11,
    Battery_Voltage: 10,
    Charging_Current: 1,
    Charging_Timer: 40,
    Temperature: 21
  }
]
