<template>
	<div></div>
</template>

<script>
export default {
  created () {
    this.$store.dispatch('web_auth/destroyToken')
      .then(response => {
        this.$router.push({ name: 'home' })
      })
  }
}
</script>
