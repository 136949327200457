<template>
  <div class="text-center">
    <h2>{{ chargerName }} Status Report</h2>
    <charger-tabs v-if="chargerName" :charger-name=chargerName></charger-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChargerTabs from '@/components/ChargerTabs'

export default {
  components: { ChargerTabs },
  data () {
    return {
      chargerName: ''
    }
  },
  computed: {
    ...mapState([
      'agvDetail'
    ])
  },
  mounted () {
    const chargerName = this.$route.params.id
    this.chargerName = chargerName
  }
}
</script>
