<template>
  <div class="page-wrapper login-form">
    <h2 class="login-heading">Login</h2>
    <form action="#" @submit.prevent="login">

      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
      <div v-if="error_code" class="server-error">{{ error_code }}</div>
      <div v-if="error_message" class="server-error">{{ error_message }}</div>

      <b-form-input v-model="username" type="email" placeholder="Enter your username/email"></b-form-input>
      <b-form-input v-model="password" type="password" placeholder="Enter your password"></b-form-input>
      <b-button type="submit" class="btn-success">Login</b-button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'login',
  props: {
    dataSuccessMessage: {
      type: String
    }
  },
  data () {
    return {
      username: '',
      password: '',
      error_code: '',
      error_message: '',
      successMessage: this.dataSuccessMessage,
      loading: false
    }
  },
  methods: {
    login () {
      this.loading = true
      this.$store.dispatch('web_auth/retrieveToken', {
        username: this.username,
        password: this.password
      })
        .then(response => {
          this.loading = false
          this.$router.push({ name: 'Status' })
        })
        .catch(error => {
          this.loading = false
          this.error_code = error.code
          this.error_message = error.error_message
          this.password = ''
          this.successMessage = ''
        })
    }
  }
}
</script>
